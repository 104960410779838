<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="mb-2 text-right">
            <b-button
              variant="info"
              class="mr-2"
              v-b-modal.add-call
            >
              <i class="fa fa-plus"></i>
            </b-button>

            <button
              type="button"
              class="mr-2 btn btn-primary"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>

          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="mt-4 mb-4 table-responsive">
              <table
                id="elderly_calls"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Elderly Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Update</th>
                    <th>Date to Retry</th>
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="call in elderlyCalls" :key="call.id">
                    <td>{{ call.elderly_name }}</td>
                    <td>{{ call.elderly_email }}</td>
                    <td>{{ call.elderly_phone }}</td>
                    <td>
                      <span
                        v-if="call.status == 'responded'"
                        class="btn btn-success"
                        >Responded</span
                      >
                      <span
                        v-if="call.status == 'unreachable'"
                        class="btn btn-info"
                        >Unreachable</span
                      >
                      <span v-if="call.status == 'retry'" class="btn btn-dark"
                        >Retry</span
                      >
                      <span
                        v-if="call.status == 'declined'"
                        class="btn btn-danger"
                        >Declined</span
                      >
                    </td>
                    <td>{{ call.update }}</td>
                    <td>
                      <span v-if="call.date_to_retry != null">{{
                        formatDate(call.date_to_retry)
                      }}</span>
                    </td>
                    <td>
                      {{ call.user.firstname || "" }}
                      {{ call.user.lastname || "" }}
                    </td>
                    <td>{{ formatDate(call.created_at) }}</td>
                    <td class="d-flex">
                      <b-button
                        variant="outline-success"
                        size="sm"
                        class="mr-3"
                        @click="showCall(call.id)"
                      >
                        <i class="fa fa-eye"></i>
                      </b-button>
                      <b-button
                        @click.prevent="showDelete(call.id)"
                        variant="outline-danger"
                        size="sm"
                        class="mr-3"
                      >
                        <i class="fa fa-trash"></i>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Modal -->
    <b-modal id="add-call" title="New Call To Elderly" size="lg" centered>
      <form>
        <div class="form-group">
          <label for="patientName">Elderly Name</label>
          <v-select
            v-model="selectedPatient"
            :options="patients"
            label="fullname"
            :filterable="false"
            @search="vselectSearch"
          >
            <template #no-options>
              type to search patients...
            </template>

            <template #list-header v-if="vselectSearchResults">
              <div class="p-3 bg-dark border-bottom d-flex justify-content-between">
                <span class="small">
                  Total: {{ vselectSearchResults.total }}<br>
                  Pages: {{ vselectSearchResults.last_page }}<br>
                  Current Page: {{ vselectSearchResults.current_page }}
                </span>
                <div class="mb-2">
                  <b-btn
                    :disabled="!vselectSearchResults.prev_page_url"
                    @click="vselectGoToPage(vselectSearchResults.current_page - 1)"
                    variant="info"
                    class="mr-2"
                    size="sm"
                    squared
                  >
                    &laquo; Prev
                  </b-btn>
                  <b-btn
                    :disabled="!vselectSearchResults.next_page_url"
                    @click="vselectGoToPage(vselectSearchResults.current_page + 1)"
                    variant="success"
                    size="sm"
                    squared
                  >
                    Next &raquo;
                  </b-btn>
                </div>
              </div>
            </template>
          </v-select>
        </div>

        <div v-if="selectedPatient">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Email</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="selectedPatient.email"
                  readonly
                />
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Phone Number </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedPatient.phone"
                  readonly
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="addModalNote">Update</label>
            <textarea
              class="form-control"
              id="addModalNote"
              rows="3"
              v-model="newElderlyCall.update"
            ></textarea>
          </div>

          <div class="form-group">
            <div><label>Status</label></div>

            <label
              class="new-control new-radio new-radio-text radio-success"
            >
              <input
                type="radio"
                class="new-control-input"
                value="responded"
                v-model="newElderlyCall.status"
              />
              <span class="new-control-indicator"></span
              ><span class="new-radio-content">Responded</span>
            </label>

            <label
              class="new-control new-radio new-radio-text radio-info"
            >
              <input
                type="radio"
                class="new-control-input"
                value="unreachable"
                v-model="newElderlyCall.status"
              />
              <span class="new-control-indicator"></span
              ><span class="new-radio-content">Unreachable</span>
            </label>

            <label
              class="new-control new-radio new-radio-text radio-dark"
            >
              <input
                type="radio"
                class="new-control-input"
                value="retry"
                v-model="newElderlyCall.status"
              />
              <span class="new-control-indicator"></span
              ><span class="new-radio-content">Retry</span>
            </label>

            <label
              class="new-control new-radio new-radio-text radio-danger"
            >
              <input
                type="radio"
                class="new-control-input"
                v-model="newElderlyCall.status"
                value="declined"
              />
              <span class="new-control-indicator"></span
              ><span class="new-radio-content">Declined</span>
            </label>
          </div>

          <div class="form-group">
            <label for="date_to_retry">Date to retry</label>
            <b-form-datepicker
              :min="today"
              id="date_to_retry"
              v-model="newElderlyCall.date_to_retry"
              class="mb-2"
            ></b-form-datepicker>
          </div>

          <div class="form-group" v-if="newElderlyCall.status === 'responded'">
            <label for="visit_id">Link to Visit</label>
            <v-select v-model="visit" :options="visits" label="appointment_date"></v-select>
          </div>

          <b-card v-if="visit && newElderlyCall.status === 'responded'">
            <b-form-group
              label="Answer the following questions"
              label-align="center"
              label-class="font-weight-bold"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox
                size="sm"
                class="mb-3"
                v-for="question in questions"
                v-model="question.status"
                :key="question.id"
                :aria-describedby="ariaDescribedby"
                switch
              >
                {{ question.title }}<br>
                <small class="text-muted">
                  {{ question.description }}
                  <span class="font-weight-bold" :class="question.status ? 'text-success' : 'text-danger'">
                    {{ question.status ? 'YES' : 'NO' }}
                  </span>
                </small>
              </b-form-checkbox>
            </b-form-group>
          </b-card>
           <div class="mt-4 form-group">
            <label for="exemplaryService">Exemplary service provided by doctor (<b>optional</b>)</label>
            <textarea
              class="form-control"
              id="exemplaryService"
              rows="3"
              v-model="newElderlyCall.exemplaryService"
            ></textarea>
          </div>
        </div>
      </form>

      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="closeAddCallModal()">
          Discard
        </b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="saveNewElderlyCall()">
          <b-spinner small v-if="loadingNew"></b-spinner>
          <span v-else>Save</span>
        </b-button>
      </template>
    </b-modal>

    <!-- View Modal -->
    <b-modal id="view-call" size="lg" centered ok-only>
      <b-table-simple v-if="elderlyCall" responsive>
        <b-tbody>
          <b-tr>
            <b-th>Elderly Name</b-th>
            <b-td>{{ elderlyCall.elderly_name }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Elderly Email</b-th>
            <b-td>{{ elderlyCall.elderly_email }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Elderly Phone</b-th>
            <b-td>{{ elderlyCall.elderly_phone }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Status</b-th>
            <b-td>{{ elderlyCall.status }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Update</b-th>
            <b-td>{{ elderlyCall.update }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Ratings</b-th>
            <b-td>
              <div class="mb-3" v-for="rating in elderlyCall.ratings" :key="rating.id">
                {{ rating.question.title }}<br>
                <small class="text-muted">
                  {{ rating.question.description }}
                  <span class="font-weight-bold" :class="rating.status ? 'text-success' : 'text-danger'">
                    {{ rating.status ? 'YES' : 'NO' }}
                  </span>
                </small>
              </div>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>Date to Retry</b-th>
            <b-td>{{ elderlyCall.date_to_retry ? formatDate(elderlyCall.date_to_retry) : '' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>

    <!-- Search Modal -->
    <div
      class="modal fade"
      id="searchModal"
      role="dialog"
      aria-labelledby="searchModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="searchModalLabel">Search Options</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="dateSearch">Date Search</label>
                  <select class="form-control" v-model="search" id="dateSearch">
                    <option value="all">All</option>
                    <option value="thisweek">This Week (From Monday)</option>
                    <option value="pastweek">Past Week (Last 7 days)</option>
                    <option value="lastmonth">Last Month</option>
                    <option value="thismonth">This Month</option>
                    <option value="thisyear">This Year</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>

                <div class="form-group" v-if="search == 'custom'">
                  <label>Select a range</label>
                  <div class="input-group">
                    <flat-pickr
                      v-model="date"
                      :config="config"
                      class="form-control"
                      placeholder="Select date"
                      @on-close="handlePicker()"
                      name="date"
                    >
                    </flat-pickr>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Discard
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="handleSearch()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete  -->
    <div
      class="modal fade"
      id="deleteModal"
      role="dialog"
      aria-labelledby="editContactModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="fa fa-close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p class="text-danger">
                    Are you sure you want to delete this Call Record?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">Cancel</button>

            <button
              @click.prevent="completeDelete()"
              class="text-white btn btn-danger"
              :disabled="loadingDelete"
            >
              <b-spinner small v-if="loadingDelete"></b-spinner>
              <span v-else>Confirm Delete <i class="fa fa-trash"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { http } from "@/utils/http";
import { gerohttp } from "@/utils/gerocarehttp";
import { endpoints, geroendpoints } from "@/utils/endpoints";
import { ProspectCall } from "@/models/prospect.js";
import { DatePickerConfig } from "@/models/datepicker.js";
import vselectPaginationSearch from "@/mixins/v-select/pagination-search";

var moment = require("moment");

export default {
  name: "AddElderlyCalls",

  mixins: [vselectPaginationSearch],

  data() {
    return {
      loading: false,
      elderlyCalls: [],
      elderlyCall: null,

      search: "thismonth",
      date: null,
      config: new DatePickerConfig(),
      customStart: "",
      customEnd: "",

      patients: [],
      selectedPatient: null,

      today: moment().toDate(),

      loadingNew: false,
      newElderlyCall: new ProspectCall(),

      loadingDelete: false,
      deleteAddElderlyCall: "",

      visits: [],
      questions: [],
      visit: null,
    };
  },

  watch: {
    selectedPatient(val) {
      this.visits = [];
      this.visit = null;

      if (val) {
        this.fetchPatientAppointmentsForRating(val.id);
      }
    },
  },

  mounted() {
    this.fetchElderlyCallRatingQuestions();
    this.fetchCallRecords();
  },

  methods: {
    vselectComponentSearch(loading, vm) {
      gerohttp
        .get(geroendpoints.FETCH_PATIENTS, {
          params: {
            'page': vm.vselectSearchPage,
            'filter[full_name]': vm.vselectSearchTerm,
          },
        })
        .then((response) => {
          vm.vselectSearchResults = response;
          vm.patients = response.data.map((patient) => {
            patient.fullname = `${patient.firstname} ${patient.midname ? patient.midname : ''} ${patient.lastname}`;

            return patient;
          });
        })
        .catch((error) => {
          vm.$toast.error(error);
        })
        .finally(() => {
          loading(false);
        });
    },

    vselectComponentClearSelection() {
      this.patients = [];
    },

    initTable() {
      var dt = $("#elderly_calls").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([1, 6, 7]).visible(false);
    },

    handleSearch() {
      if (
        this.search == "custom" &&
        (this.customStart == "" || this.customEnd == "")
      ) {
        this.$toast.error("Enter a valid date range");
      } else {
        this.fetchCallRecords();
        $("#searchModal").modal("hide");
      }
    },

    handlePicker() {
      setTimeout(() => {
        if (this.date.length < 15) {
          this.$toast.error("Enter a valid date range");
        } else {
          var split = this.date.split(" ");
          this.customStart = split[0];
          this.customEnd = split[2];
        }
      }, 500);
    },

    fetchElderlyCallRatingQuestions() {
      http
        .get(endpoints.FETCH_ELDERLY_CALL_RATING_QUESTIONS)
        .then((response) => {
          this.questions = response.map((question) => {
            question.status = false;
            return question;
          });
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    fetchCallRecords() {
      this.loading = true;
      http
        .get(endpoints.ALL_ELDERLY_CALLS, {
          params: {
            search: this.search,
            customStart: this.customStart,
            customEnd: this.customEnd,
          }
        })
        .then((response) => {
          this.loading = false;
          this.elderlyCalls = response;
          setTimeout(() => {
            this.initTable();
          }, 500);
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    fetchPatientAppointmentsForRating(patientId) {
      gerohttp
        .get(geroendpoints.FETCH_PATIENTS_APPOINTMENTS_FOR_RATING.replace(
          ":patientId",
          patientId
        ))
        .then((response) => {
          this.visits = response.map((visit) => {
            visit.appointment_date = this.formatFancyDateFull(visit.appointment_date);
            return visit;
          });
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    showCall(id) {
      http
        .get(endpoints.FETCH_ELDERLY_CALL.replace(":id", id))
        .then((response) => {
          this.elderlyCall = response;
          this.$bvModal.show('view-call');
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    saveNewElderlyCall() {
      if (this.newElderlyCall.status == "") {
        this.$toast.error("Please give a status");
      } else {
        this.loadingNew = true;
        http
          .post(endpoints.NEW_ELDERLY_CALLS, {
            elderly_id: this.selectedPatient.id,
            elderly_name: this.selectedPatient.fullname,
            elderly_email: this.selectedPatient.email,
            elderly_phone: this.selectedPatient.phone,
            status: this.newElderlyCall.status,
            update: this.newElderlyCall.update,
            exemplary_service: this.newElderlyCall.exemplaryService,
            date_to_retry: this.newElderlyCall.date_to_retry,
            visit_id: this.visit?.id,
            ratings: this.questions.map((question) => {
              return {
                elderly_call_rating_question_id: question.id,
                status: question.status,
              };
            }),
          })
          .then((response) => {
            this.loadingNew = false;
            this.$toast.success(response);
            this.newElderlyCall = new ProspectCall();
            this.fetchCallRecords();
            this.closeAddCallModal();
          })
          .catch((error) => {
            this.loadingNew = false;
            this.$toast.error(error.response?.data?.message || error.response?.message);
          });
      }
    },

    showDelete(x) {
      this.deleteAddElderlyCall = x;
      $("#deleteModal").modal("show");
    },

    completeDelete() {
      this.loadingDelete = true;
      http
        .delete(
          endpoints.DELETE_ELDERLY_CALL.replace(
            ":id",
            this.deleteAddElderlyCall
          )
        )
        .then((response) => {
          this.loadingDelete = false;
          $("#deleteModal").modal("hide");
          this.$toast.success(response);
          this.fetchCallRecords();
        })
        .catch((error) => {
          this.loadingDelete = false;
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    closeAddCallModal() {
      this.selectedPatient = null;
      this.$bvModal.hide('add-call');
    }
  },
};
</script>
